<template>
  <div class="servicelanding"> <!-- landing-->
    <div class="">
      <h1>Clinic & Hospitals</h1>
      <h1>Branding ThriveRx</h1>
      <hr>
      <button><a href="#Healthcareform">Contact Us</a></button>
      <img src="../assets/healthcareimg/Clinic&Hospitals/Group23.png">
    </div>
    <div class="servicelandingimg">
      <img src="../assets/healthcareimg/Clinic&Hospitals/Card.png">
    </div>
  </div>
  <div class="cnhboxes"><!--Five boxes-->
    <div class="cnhbox">
      <img src="../assets/healthcareimg/Clinic&Hospitals/icons8-roi-64.png" style="padding: 10px;">
      <p style="padding: 10px;">ROI Driven Marketing to ensure High Returns on your Brand investments</p>
    </div>
    <div class="cnhbox">
      <img src="../assets/healthcareimg/Clinic&Hospitals/icons8-media-96.png" style="padding: 10px;">
      <p style="padding: 10px;">Social Media Presence Management</p>
    </div>
    <div class="cnhbox">
      <img src="../assets/healthcareimg/Clinic&Hospitals/icons8-healthcare-64.png" style="padding: 10px;">
      <p style="padding: 10px;">Healthcare performance Marketing</p>
    </div>
    <div class="cnhbox">
      <img src="../assets/healthcareimg/Clinic&Hospitals/icons8-hospital-sign-100.png" style="padding: 10px;">
      <p style="padding: 10px;">Healthcare Personalised Branding</p>
    </div>
    <div class="cnhbox">
      <img src="../assets/healthcareimg/Clinic&Hospitals/icons8-favorite-window-96.png" style="padding: 10px;">
      <p style="padding: 10px;">Reviews & Reputation Management</p>
    </div>
  </div>
  <div class="jog">
    <h1>Join Our Growing List Of Indian</h1>
    <h1><span>HEALTHCARE CLIENTS</span></h1>
    <div class="jogimg">
    <img src="../assets/healthcareimg/HealthcareClientsLogos/DrKaur.png">
    <img src="../assets/healthcareimg/HealthcareClientsLogos/KashiNetralaya.png">
    <img src="../assets/healthcareimg/HealthcareClientsLogos/AeonClinic.png">
    <img src="../assets/healthcareimg/HealthcareClientsLogos/Diabendocare.png">
  </div>
  </div>
  <div class="wkyab">
    <h2>We Know You Are Busy!</h2>
    <h4>Let us call you at your preferred Time</h4>
    <a href="#Healthcareform">Get a Callback</a>
  </div>
  <div class="sservice">
    <h2>Services</h2>
    <hr>
  </div>
  <div class="pcmw">
    <h1>Patient-Centric Medical Website</h1>
    <img src="../assets/healthcareimg/PatientCentricMedicalWebsite/Group27.png" class="pcmwimg">
    <div class="cnhboxes">
      <div class="cnhbox"><img src="../assets/healthcareimg/PatientCentricMedicalWebsite/icons8-web-96.png">
      <p style="padding: 10px;">15+ years of experience in building websites</p>
      </div>
      <div class="cnhbox"><img src="../assets/healthcareimg/PatientCentricMedicalWebsite/icons8-design-90.png">
      <p style="padding: 10px;">User Centric Design</p>
      </div>
      <div class="cnhbox"><img src="../assets/healthcareimg/PatientCentricMedicalWebsite/icons8-administrator-male-96.png">
      <p style="padding: 10px;">100% Client Owned</p>
      </div>
      <div class="cnhbox"><img src="../assets/healthcareimg/PatientCentricMedicalWebsite/icons8-web-hosting-64(1).png">
      <p style="padding: 10px;">Lowest Web Hosting Fees</p>
      </div>
      <div class="cnhbox"><img src="../assets/healthcareimg/PatientCentricMedicalWebsite/icons8-free-60(1).png">
      <p style="padding: 10px;">Free Website redesign after every 2 years</p>
      </div>
      <div class="cnhbox"><img src="../assets/healthcareimg/PatientCentricMedicalWebsite/icons8-integration-64.png">
      <p style="padding: 10px;">Integration with third - party tools</p>
      </div>
    </div>
  </div>
    <div class="SMM" >
      <h1>Social Media Marketing</h1>
      <div class="SMMcarousel">
        <div class="SMMcarouselimg">
          <img src="../assets/healthcareimg/SocialMediaMarketing/Myths&FactsEng24thnov.png">
          <img src="../assets/healthcareimg/SocialMediaMarketing/mainpg.png">
          <img src="../assets/healthcareimg/SocialMediaMarketing/ZeissIOLMaster700.png">
          <img src="../assets/healthcareimg/SocialMediaMarketing/5.png">
          <img src="../assets/healthcareimg/SocialMediaMarketing/3.png">
        </div>
      </div>
    </div>
    <div class="gafap">
      <h1>Google Ads, Facebook Ads PPC</h1>
      <!-- <img src="../assets/healthcareimg/GoogleAdsPPC/image1.png" class="adimg"> -->
    </div>
    <div class="cnhboxes">
      <div class="cnhbox">
        <img style="padding: 10px;" src="../assets/healthcareimg/GoogleAdsPPC/icons8-keywords-961.png">
        <p style="padding: 10px;">Targeted Keywords</p>
      </div>
      <div class="cnhbox">
        <img style="padding: 10px; width: 33%;" src="../assets/healthcareimg/GoogleAdsPPC/icons8-country-1001.png">
        <p style="padding: 10px;">Local Targeting</p>
      </div>
      <div class="cnhbox">
        <img style="padding: 10px; width: 33%;" src="../assets/healthcareimg/GoogleAdsPPC/icons8-copy-641.png">
        <p style="padding: 10px;">Ad Copy</p>
      </div>
      <div class="cnhbox">
        <img style="padding: 10px; width: 33%;" src="../assets/healthcareimg/GoogleAdsPPC/icons8-landing-page-641.png">
        <p style="padding: 10px;">Landing Page Optimization</p>
      </div>
      <div class="cnhbox">
        <img style="padding: 10px; width: 33%;" src="../assets/healthcareimg/GoogleAdsPPC/icons8-budget-961.png">
        <p style="padding: 10px;">Budget  & Bidding</p>
      </div>
      <div class="cnhbox">
        <img style="padding: 10px; width: 33%;" src="../assets/healthcareimg/GoogleAdsPPC/icons8-optimization-641.png">
        <p style="padding: 10px;">Continuous Optimization</p>
      </div>
      <div class="cnhbox">
        <img style="padding: 10px; width: 33%;" src="../assets/healthcareimg/GoogleAdsPPC/icons8-analysis-901.png">
        <p style="padding: 10px;">Tracking & Analytics for ROI</p>
      </div>
    </div>
    <div class="HRM">
      <h1>Healthcare Reputation Management</h1>
      <div class="HRMimg">
        <img src="../assets/healthcareimg/HealthcareReputationManagement/BestEyeCareHospitalinVaranasi.png">
        <img src="../assets/healthcareimg/HealthcareReputationManagement/BestEyeCareHospitalinVaranasi3.png">
      </div>
      <div class="cnhboxes">
        <div class="cnhbox"><img src="../assets/healthcareimg/HealthcareReputationManagement/icons8-roi-642(1).png" style="padding: 10px;">
        <p style="padding: 10px;">Improved ROI</p>
        </div>
        <div class="cnhbox"><img src="../assets/healthcareimg/HealthcareReputationManagement/icons8-customer-insight-961(1).png" style="padding: 10px;">
        <p style="padding: 10px;">Excellent Patient Experience</p>
        </div>
        <div class="cnhbox"><img src="../assets/healthcareimg/HealthcareReputationManagement/icons8-reputation-641(1).png" style="padding: 10px;">
        <p style="padding: 10px;">Stellar Online Reputation</p>
        </div>
        <div class="cnhbox"><img src="../assets/healthcareimg/HealthcareReputationManagement/icons8-reliability-68(2).png" style="padding: 10px;">
        <p style="padding: 10px;">Reliability & Credibility</p>
        </div>
        <div class="cnhbox"><img src="../assets/healthcareimg/HealthcareReputationManagement/icons8-monitoring-1001(1).png" style="padding: 10px;">
        <p style="padding: 10px;">Monitoring</p>
        </div>
        <div class="cnhbox"><img src="../assets/healthcareimg/HealthcareReputationManagement/icons8-listing-641(2).png" style="padding: 10px;">
        <p style="padding: 10px;">Consistency listing</p>
        </div>
        <div class="cnhbox"><img src="../assets/healthcareimg/HealthcareReputationManagement/icons8-loyalty-1001(1).png" style="padding: 10px;">
        <p style="padding: 10px;">Satisfied & loyal Patient base</p>
        </div>
      </div>
    </div>
    <!-- <div class="wocsau">
      <h1>What <span>Our Clients</span> Say About Us</h1>
      <div class="">
        <div class="">1</div>
        <div class="">2</div>
      </div>
    </div> -->
    <div class="featuredon">
      <h1>Featured On</h1>
      <div class="featuredimg">
        <img src="../assets/healthcareimg/FeaturedOn/TOI.png">
        <img src="../assets/healthcareimg/FeaturedOn/HT.png">
      </div>
    </div>
    <div class="bypwt" style="padding: 2rem;" id="Healthcareform">
      <h1>Boost Your Practice With <span>ThriveRx</span></h1>
      <div>
        <div class="bypwtform">
          <form id="healthcareform" action="https://script.google.com/macros/s/AKfycbyX8o-6XG2CkC0Re-ZSE9YGhpgGj82jL_-inGaV_Fqsvx6F_eNQ-uhmqVT1-gk77Gn-Cw/exec" method="post" @submit.prevent="SubmitForm" style="display: flex; flex-direction: column;">
          <input type="text" placeholder="Clinic/Hospital Name" name="Hospitalname" onkeydown="return /[a-z ]/i.test(event.key)" required>
          <input type="text" placeholder="Name" name="Name" onkeydown="return /[a-z ]/i.test(event.key)" required>
          <input type="text" placeholder="Phone no" name="Contact" onkeydown="return this.value.length <= 9 && /[0-9\b]/.test(event.key) || event.keyCode === 8" required>
          <input type="email" placeholder="Email" name="Email" required>
          <button type="submit">Schedule Initial Call</button>
        </form>
        </div>
      </div>
    </div>

    <div v-if="showOptions" class="popupi">
      <div class="popupii">
        <button type="button" id="closePopupButton" class="closebtn" @click="closePopup">x</button>
          <div class="popupiii  ">
                             <!-- Email Button -->
            <button @click="sendEmail" class="emailbtn">Email Us</button>

                                 <!-- WhatsApp a -->
            <button @click="sendWhatsApp" class="wabtn">WhatsApp Us</button>

                                 <!-- Call a -->
            <button @click="makeCall" class="callbtn">Call Us</button>
          </div>
      </div>
    </div>

    <div class="">
    <h2 class="prime">Our Pricing Plans</h2>
    <!-- <p class="heero"> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod, voluptate? ratione inventore facere maiores odit porro ullam quidem! Molestiae eius a qui dignissimos eos, non architecto culpa veritatis ipsa odit praesentium.</p> -->
   
    <!-- <div  class="btn-container">
      <i class="fa fa-sun-o" aria-hidden="true"></i>
        <label class="switch btn-color-mode-switch">
              <input type="checkbox" name="color_mode"  v-model="isChecked"  @change="toggleContent" id="color_mode" value="1">
              <label for="color_mode" :data-on="toggleText.on" :data-off="toggleText.off" class="btn-color-mode-switch-inner"></label>
            </label>
            </div> -->
    
    <div  class="roww">
      <!--<input type="checkbox"  v-model="checked" @change="onChange">-->
        <div  class="cardd">
            <h4 class="headi" style="color: #fff;">Starter Package</h4>
            <div  class="card__detai">
                             <ul>
                              <h3  v-if="isChecked" class="pricee">₹8999/- Per month</h3>
                              <h3  v-else class="pricee">₹8999/- Per month</h3>
                                <p class="hero_para">Establish your healthcare brand online with essential content and setup.</p>
                                <li><img src="../assets/Pointer.png" class="pit">Engaging Content Creation</li>
                                <li><img src="../assets/Pointer.png" class="pit">Tailored Educational & Promotional Posts</li>
                                <li><img src="../assets/Pointer.png" class="pit">Instagram & Facebook Optimization</li>
                                <li><img src="../assets/Pointer.png" class="pit">Google My Business Optimization </li>
                                <li><img src="../assets/Pointer.png" class="pit">Enhanced Visibility on Google Maps</li>
                             </ul>
                             <a @click="showContactOptions" class="butto">Get Started</a>
                             
                            </div>
                            
        </div>
         <div  class="cardd" style="background-color:white;">
            <h4 class="headi" style="color:black">Growth Package</h4>
            <div  class="card__detai">
                             <ul >
                                <h3 class="pricee" v-if="isChecked" style="color:black">₹14999/- Per month</h3>
                                <h3 class="pricee" v-else style="color:black">₹14999/- Per month</h3>
                                <p class="hero_para"> Expand your healthcare brand's reach with strategic content and advertising.</p>
                                <li style="color:black"><img src="../assets/Pointer.png" class="pit">Engaging Content Creation</li>
                                <li style="color:black"><img src="../assets/Pointer.png" class="pit">Tailored Educational & Promotional Posts</li>
                                <li style="color:black"><img src="../assets/Pointer.png" class="pit">Instagram & Facebook Optimization</li>
                                <li style="color:black"><img src="../assets/Pointer.png" class="pit" >Google My Business Optimization </li>
                                <li style="color:black"><img src="../assets/Pointer.png" class="pit">Enhanced Visibility on Google Maps</li>
                                <li style="color:black"><img src="../assets/Pointer.png" class="pit" >Reels Edits and references</li>
                                <li style="color:black"><img src="../assets/Pointer.png" class="pit">Awareness Ads & Google Ads</li>
                             </ul>
                             <a @click="showContactOptions" class="butto">Get Started</a>
                            </div>
        </div>
         <div  class="cardd">
         
            <div  class="card__detai">
                <h4 class="headi" style="color: #fff;">Optimization Package</h4>
                             <ul>
                                <h3  v-if="isChecked" class="pricee">₹29999/- Per month</h3> 
                                <h3  v-else class="pricee">₹29999/- Per month</h3>
                                <p class="hero_para">Unleash your healthcare brand's digital presence with optimization and branding for impact growth.</p>
                                <li> <img src="../assets/Pointer.png" class="pit">Engaging Content Creation</li>
                                <li><img src="../assets/Pointer.png" class="pit">Tailored Educational & Promotional Posts</li>
                                <li><img src="../assets/Pointer.png" class="pit">Instagram & Facebook Optimization</li>
                                <li><img src="../assets/Pointer.png" class="pit">Google My Business Optimization </li>
                                <li><img src="../assets/Pointer.png" class="pit">Enhanced Visibility on Google Maps</li>
                                <li><img src="../assets/Pointer.png" class="pit" >Reels Edits and references</li>
                                <li><img src="../assets/Pointer.png" class="pit" >Lead Generation Ads</li>
                                <li><img src="../assets/Pointer.png" class="pit">Awareness Ads & Google Ads</li>
                                <li><img src="../assets/Pointer.png" class="pit">Brand Language,logo and typography</li>
                                
                             </ul>
                             <a @click="showContactOptions" class="butto">Get Started</a>
                            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default{
    name: 'HealthServices',
    data() {
        return {
          showOptions:false,
            isChecked: true,
            toggleText: {
                on: 'monthly', // Text for the 'on' state
                //off: 'yearly' // Text for the 'off' state
            }
        };
    },
    methods: {
        SubmitForm() {
            const form = document.getElementById('healthcareform');
            const data = new FormData(form);
            const action = form.action;
            fetch(action, {
                method: 'POST',
                body: data,
            })
                .then(() => {
                this.showAlert = true;
                alert('We will be connect with you soon!');
                form.reset();
            })
                .catch((error) => console.log(`Error submitting contact form ${error}`));
        },
        closePopup() {
      this.showOptions = false;
    },
            showContactOptions() {
                    this.showOptions = true;
                },
                sendEmail() {
                    var subject = "ThriveRx Get Started";
                    var body = "Hi ThriberX I would like to know more about ThriveRx. Please contact me ASAP";
                    var recipient = "accelerate@thriber.com";
                    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                },
                sendWhatsApp() {
                    var message = "Hi ThriberX I would like to know more about ThriveRx. Please contact me ASAP";
                    window.open("https://api.whatsapp.com/send?phone=918104531950&text=" + encodeURIComponent(message), '_blank');
                },
                makeCall() {
                    // Replace with your actual phone number
                    window.location.href = "tel:+918104531950";
                }
    },
    
}
</script>


<style>

*,
    *::after,
    *::before {
      margin: 0;
      padding: 0;
      box-sizing: inherit; 
      
    }
    body{
        background-color: black;
        display: table;
    }

.servicelanding{
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.servicelanding h1{
  color: var(--primary-white-100, #FFF);
text-shadow: 2px 2px 2px #12DBE5;
font-size: 75px;
font-style: normal;
font-weight: 600;
line-height: 112px;
}

.servicelanding hr{
  color: #fff;
  height: 2px;
  opacity: 1;
}

.servicelanding a{
  color: #fff;
  text-decoration: none;
}

.servicelanding button{
  border-radius: 16px;
  color: #fff;
  border: 1px solid #000;
  text-decoration: none;
  padding: 20px;
  margin-top: 70px;
  margin-right: 20px;
  background: linear-gradient(92deg, #12D4E2 0%, #1F5799 100%);
}

.cnhboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: space-evenly;
    color: #fff;
    padding: 40px;
}

.cnhbox{
  padding: 20px;
  border-radius: 20px;
  background-color: #151515;
  width: 300px;
  
}

.cnhbox p{
  color: rgba(255, 255, 255, 0.75);
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
font-size: 2rem;
font-style: normal;
font-weight: 500;
line-height: 40px; 
letter-spacing: -0.6px;
}

.jog h1{
  color: var(--primary-white-100, #FFF);
  text-align: center;
  font-size: 75px;
  font-style: normal;
  font-weight: 600;
  line-height: 112px;
}

.jog h1 span{
  background: linear-gradient(180deg, #12D4E2 24%, #1F5799 75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.jogimg{
  width: 91%;
  margin: auto;
}

.jogimg img{
  padding: 20px;
}

.wkyab{
  background-image: url('../assets/healthcareimg/Servicestextbgimg/Card(1).png');
  background-position: center;
  background-size: cover;
  width: 80%;
  border-radius: 3rem;
  margin: auto;
  text-align: center;
  padding: 20px;
  margin-bottom: 4rem;
}

.wkyab h2{
  color: var(--primary-white-100, #FFF);
text-align: center;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
font-size: 52px;
font-style: normal;
font-weight: 600;
line-height: 64px;
letter-spacing: -2.6px;
}

.wkyab h4{
  color: rgba(255, 255, 255, 0.75);
text-align: center;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 28px; 
letter-spacing: -0.44px;
padding: 10px;
}

.wkyab a{
  border-radius: 10px;
  background: linear-gradient(77deg, #12DBE5 0%, #1F5799 50%, #F20000 100%);
  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  text-decoration: none;
  border: 0px solid;
  color: #fff;

}

.sservice{
  background-image: url('../assets/healthcareimg/Servicestextbgimg/Card(1).png');
  background-position: center;
  background-size: cover;
  width: 80%;
  margin: auto;
  padding: 20px;
  text-align: center;
  margin-bottom: 4rem;
  border-radius: 3rem;
}

.sservice h2{
  color: var(--primary-white-100, #FFF);
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 112px; /* 140% */
  text-transform: capitalize;
  text-align: center;
}

.sservice hr{
  color: #fff;
  height: 2px;
  opacity: 1;
  width: 30%;
  margin: auto;
}

.pcmw{
  display: flex;
  flex-direction: column;
}

.pcmw h1{
  color: #FFF;
text-align: center;
font-size: 70px;
font-style: normal;
font-weight: 700;
line-height: 100px;
}

.pcmw .pcmwimg{
  margin: auto;
  width: 80%;
}

.SMM h1{
  color: #FFF;
  text-align: center;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px; /* 142.857% */
}

.SMMcarousel{
  background-image: url('../assets/healthcareimg/SocialMediaMarketing/whitemobile.png');
  background-size: cover;
  background-position: center;
  width: 250px;
  height: 520px; 
  margin: auto;
  position: relative;
  overflow: hidden;
}

.SMMcarouselimg{
  position: absolute;
  transform: translate(-50%,-50%);
    z-index: 99;
    width: 500px;
    gap: 10px;
    margin: auto;
    display: flex;
    align-items: center;
    /* right: 63%; */
    margin-top: 150px;
    animation: slide 26s infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  0%{
    transform: translateX(0);
  }
  25% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(-100%);
  }
  75% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(-200%);
  }
}


.SMMcarouselimg img {
    width: 34%;
    margin: 7%;
}

.gafap{
  text-align: center;
  padding: 2rem;
}

.gafap h1{
  color: #FFF;
text-align: center;
font-size: 70px;
font-style: normal;
font-weight: 700;
line-height: 100px;
}

.gafap .adimg{
  width: 20%;
  margin: auto;
}

.HRM h1{
  color: #FFF;
  text-align: center;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
}

.HRMimg{
  display: flex;
  justify-content: space-evenly;
}

.HRMimg img{
  width: 30%;
}

.wocsau h1{
  color: #FFF;
  text-align: center;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
}

.wocsau span{
  background: linear-gradient(180deg, #12D4E2 0%, #1F5799 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 100px;
  text-transform: uppercase;
}

.featuredon h1{
  color: #FFF;
  text-align: center;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
}

.featuredimg {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    padding: 3rem;
}

.bypwt h1{
  color: #FFF;
  text-align: center;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px; 
}

.bypwt span{
  background: linear-gradient(180deg, #12D4E2 23%, #1F5799 75%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 70px;
font-style: normal;
font-weight: 800;
line-height: 100px;
}

.bypwtform{
  background-image: url('../assets/healthcareimg/BoostyourPractisewithThriveRxBG/Card(2).png');
  background-position: center;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  border-radius: 3rem;
}

.bypwtform input{
  width: 400px;
  margin: auto;
  margin-bottom: 2rem;
  background-color: #000;
  border-radius: 10px;
  padding: 1rem;
  color: #fff;
  border: 1px solid #000;
}

.bypwtform button{
  border-radius: 16px;
  background: linear-gradient(83deg, #12DBE5 5.52%, #1F5799 49.07%, #F20000 94.48%);
  padding: 10px;
  color: #fff;
  width: 200px;
  margin: auto;
  border: 0px solid;
}

    .heero{
        text-align: center;
        padding: 2rem;
        margin: 0 10rem 0 10rem;
    }
    .prime{
        text-align: center;
        color: white;
        font-size: 5rem;
    }
    
.btn-container{
  vertical-align: middle;
  text-align: center; 
}

.btn-container i{
  display: inline-block;
  position: relative;
  top: -8px;

}

label {
  font-size: 13px;
  color: #424242;
  font-weight: 800;

}

.btn-color-mode-switch{
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner{
  margin: 0px;
  width: 150px;
  height: 42px;
  background: #E0E0E0;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
  
}
/* .btn-color-mode-switch > label.btn-color-mode-switch-inner:before{
  content: attr(data-on);
  position: absolute;
  font-size: 12px;
  font-weight: 800;
  top: 10px;
  right: 20px;

} */

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after{
  content: attr(data-off);
  width: 70px;
  height: 35px;
  background: #fff;
  border-radius: 26px;
  position: absolute;
  left: 5px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 5px 0px;
}

.btn-color-mode-switch > .alert{
  display: none;
  background: #FF9800;
  border: none;
  color: #fff;
}

.btn-color-mode-switch input[type="checkbox"]{
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner{
  background: #151515;
  color: #fff;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after{
  content: attr(data-on);
  left: 68px;
  background: #3c3c3c;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before{
  content: attr(data-off);
  right: auto;
  left: 20px;
}
.btn-color-mode-switch input[type="checkbox"]:checked ~ .alert{
  display: block;
}



    .roww{
        background-color: black;
        display: flex;
        flex-direction: row;    
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .cardd{
        margin: 2rem;
        height: 100%;
       width:25%;
       background: var(--gray-800, #1F1F1F);
       border-radius: 40px;
       text-align: center;
    }
    .headi{
        padding:  3rem 0 3rem 2.2rem;
        font-size: 3rem;
        text-align: left;
    }
    .pit{
        padding-right: 2rem;
    }
    .card__detai a{
        text-align: center;
        background: linear-gradient(92deg, #12DBE5 0%, #1F5799 51.04%, #F20000 100%); 
        text-decoration: none;
        padding: 1.5rem 4rem;
        border-radius: 1rem;
        transition: all .2s;
        font-size: 1.5rem;
        display: inline-block;
        border: none;
        cursor: pointer;
        color:white;
        width: 80%;
        margin-bottom: 2rem;
    }
    .card__detai h3{
        font-size: 3.2rem;
        text-align: left;
        color: white;
        font-weight: 700;
    }
    .card__detai p{
        padding: 2rem 0 2rem;
        text-align: left;
        font-weight: 600;
    }
    .card__detai ul{
        list-style: none;
        padding-bottom: 3rem;
    }
    .card__detai li{
        text-align: left;
        padding-bottom: 2rem;
        font-weight: 700;
        padding: 1rem;
        color: white;
    }


.popupi{
  position: fixed;
  background-color: #1f1f1f80;
  padding: 100%;
  z-index: 9999;
}

 .popupii {
    position: fixed;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    padding: 2rem;
    gap: 2rem;
    width: 80%;
    height: fit-content;
    z-index: 99999;
    background-color: #1f1f1f;
    border-radius: 2rem;
}

.popupiii{
  display: flex;
  gap: 20px;
}

.emailbtn{
  padding: 1rem;
        background: linear-gradient(92deg, #12DBE5 0%, #1F5799 51.04%, #F20000 100%); 
  width: 20%;
  color: #fff;
  border: 0px;
  border-radius: 20px;
  margin: 10%  auto;
}

.wabtn{
  padding: 1rem;
        background: linear-gradient(92deg, #12DBE5 0%, #1F5799 51.04%, #F20000 100%); 
  width: 30%;
  color: #fff;
  border: 0px;
  border-radius: 20px;
  margin: 10% auto;
}

.callbtn{
  padding: 1rem;
        background: linear-gradient(92deg, #12DBE5 0%, #1F5799 51.04%, #F20000 100%); 
    width: 30%;
    color: #fff;
    border: 0px;
    border-radius: 20px;
    margin: 10% auto;
}

.closebtn {
  width: 4%;
  background-color: #fff;
  border: 0px;
  border-radius: 20px;
  margin-right: 100%;
  margin-bottom: 2rem;
}

   
    @media (max-width: 1215px) {
      .servicelanding{
        flex-direction: column;
      }

      .jogimg{
        text-align: center;
        width: 80%;
      }
    }

    @media (max-width:900px) {
      .servicelanding{
        text-align: center;
      }

      .servicelandingimg img{
        width: 80%;
      }

      .HRMimg {
        flex-direction: column;
        gap: 20px;
      }

      .HRMimg img{
        margin: auto;
        width: 50%;
      }

      .bypwt input{
        width: 100%;
      }

      .roww{
        flex-direction: column;
      }
      .cardd{
        width: 70%;
      }

      .popupiii{
        flex-direction: column;
      }

      .emailbtn{
        width: 50%;
      margin: 1% auto;
      }

      .callbtn{
        width: 50%;
      margin: 1% auto;
      }

      .wabtn{
      width: 50%;
      margin: 1% auto;
    }

    .closebtn{
      width: 10%;
    }

  }
    @media (max-width:650px) {
      .servicelanding h1{
        font-size: 50px;
        line-height: normal;
      }

      .servicelandingimg img{
        width: 60%;
      }

      .jog h1{
        font-size: 50px;
        line-height: normal;
      }

      .wkyab h2{
        font-size: 3rem;
        line-height: normal;
      }

      .wkyab h4{
        font-size: 2rem;
        line-height: normal;
      }

      .sservice h2{
        font-size: 3rem;
        line-height: normal;
      }

      .pcmw h1{
        font-size: 4rem;
        line-height: normal;
      }

      .SMM h1{
        font-size: 4rem;
        line-height: normal;
      }

      .gafap h1{
        font-size: 4rem;
        line-height: normal;
      }

      .HRM h1{
        font-size: 4rem;
        line-height: normal;
      }

      .featuredon h1{
        font-size: 4rem;
        line-height: normal;
      }

      .bypwt h1{
        font-size: 4rem;
        line-height: normal;
      }

      .bypwt span{
        font-size: 4rem;
        line-height: normal;
      }

      .heero {
        padding: 1rem;
        margin: 0 3rem 0 3rem;
    }

    .featuredimg{
      flex-direction: column;
      gap: 20px;
      width: 80%;
      margin: auto;
    }

    .gafap .adimg{
      width: 50%;
    }

    .jogimg img{
      width: 80%;
    }

  }
    @media (max-width:315px){
      .card__detai h3{
        font-size: 2.5rem;
    }

    .jogimg{
      width: 80%;
    }
    }
    
</style>