<template>
    
    <section  class="about_us" id="ourteam">
        
        <div class="primary_heading">
            <h2 class="heading">
                OUR TEAM
            </h2>
        </div>
  
  
        <div class="row-container">
            <div class="row_c" >
              <div class="team" v-for="(team, index) in teams" :key="index" :style="getTeamStyle(index)">
                <figure class="shape">
                  <img :src="team.photo" :alt="team.name" class="photo">
                </figure>
                <div class="text">
                  <h3 class="name">{{ team.name }} <a :href="team.link" target="_blank"><img src="../assets/linkedinlogo.png" alt="logo" class="link_photo"></a></h3>
                  <h3 class="role">{{ team.role }}</h3>
                  <p class="desc">{{ team.description }}</p>
                </div>
              </div>
            </div>
            <button @click="prevSlide" class="carousel-button prev">&#x1F82C;</button>
            <button @click="nextSlide" class="carousel-button next"> &#x1F82E;</button>
        </div>
        <div class="carousel-dots">
          <span :class="{ active: currentIndex === dotIndex }" v-for="(dot, dotIndex) in teams.length" :key="dotIndex" class="dot"></span>
        </div>
        
    </section>
    
  </template>
  
  <script>
  export default{
    name: 'our_team',
    data() {
    return {
      teams: [
        {
          name: "Achintye Madhav Singh",
          role: "Chief Executive Officer",
          photo: require('@/assets/Achintye.png'),
          description:
            "Achintye is a seasoned Software Engineer with close to 8 years of experience. He graduated from IIIT in 2015 with a Computer Science and Engineering degree. He has worked with diverse businesses in the technology domain, dealing in e-commerce, stock brokerage firms, real estate and FMCG. In his most recent stint, he was an  engineering leader at SYFE.com. He works full time as Chief Executive Officer.",
            link:'https://www.linkedin.com/in/achintye/',
        },
        // {
        //     name: "Vitthal Kushe",
        //   role: "Chief Operations Officer",
        //   photo:require('@/assets/Vitthal.png'),
        //   description:
        //     "Meet our esteemed software engineer and product expert with extensive experience working at Lodha Ventures.  With a specialization in Python, Node JS and Cloud Solution Architecture, he has successfully established two businesses with strong annual revenue. His unique combination of technical expertise and a deep understanding of people and product have set him apart in the industry.",
        //     link:'https://www.linkedin.com/in/vitthal-kushe/',
        // },
        {
            name: "Ajinkya Gadkari",
          role: "Graphic Designer",
          photo: require('@/assets/Ajinkya.png'),
          description:
            "Ajinkya is an accomplished Graphic Designer with a Bachelor's degree in Mass Media and a solid two-year track record in the field. With a natural flair for creativity, he specializes in crafting visually captivating designs. Currently serving as the Graphic Designer Head at Thriber on a full-time basis, he is committed to transforming your concepts into stunning visual realities. Together, you can embark on a creative journey that promises to bring your ideas to life.",
            link:'https://www.linkedin.com/in/ajinkya-gadkari-913798103/',
        },
        {
            name: "Siddhesh Bhalerao",
          role: "Head Of Marketing",
          photo: require('@/assets/siddhesh.png'),
          description:
            "Meet our accomplished Head of Marketing, bringing years of comprehensive marketing experience, both in digital and traditional realms. Specializing in leveraging digital platforms and traditional channels, He has played a pivotal role in elevating brand visibility and driving revenue. His proficiency extends across various domains, making them a valuable asset in steering marketing initiatives to success.",
            link:'https://www.linkedin.com/in/siddhesh-bhalerao-67ba8b212/' ,
        },
        {
            name: "Sandeep Vishwakarma",
          role: "Software Developer",
          photo: require('@/assets/Sandeep.png'),
          description:
            "Welcome to innovation central! Meet Sandeep, our tech visionary. Armed with a BSc in Computer Science and a Master's in Computer Application, Sandeep brings a wealth of digital expertise. He has honed his skills in crafting cutting-edge solutions. His unwavering commitment to software development engineering ensures precision and innovation in every project.",
            link:'https://www.linkedin.com/in/sandeeprvishwakarma',
        },
        {
            name: "Sarthak Singh",
          role: "Software Developer",
          photo: require('@/assets/sarthak.png'),
          description:
            "Meet Sarthak Singh, our Web development specialist and industry expert. His professional and enthusiastic approach to frontend development showcases his commitment to excellence in the tech industry. With a dedicated focus on staying updated with the latest advancements, he stands among the top talents in the field.",
            link:'http://linkedin.com/in/sarthak-singh-402a91212',
        },
      ],
      currentIndex: 0,
      numTeamsPerPage: 1,
      autoSlideInterval: null,
    };
  },
    computed: {
    getTeamStyle() {
      return (index) => {
        const left = `${(index - this.currentIndex) * (100 / this.numTeamsPerPage)}%`;
        return { left };
      };
    },
  },
  methods: {
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.teams.length;
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.teams.length) % this.teams.length;
    },
    
  },
  };
  
  
  </script>
  
  <style >
  .about_us{
    background-color: black;
    height: 100%;
  }
  .row-container {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  .heading{
    color: var(--primary-white-100, #FFF);
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    padding: 10px 20px;
    margin-bottom: 8rem;
  }
  .row_c{
    height: 40rem;
    width: 95%;
    margin:auto;
    position: relative;
    overflow: hidden;
    left:10%;
    padding-bottom:3rem;
    border-radius: 2rem;
  }
  .link_photo{
    width: 3%;
  }
  .team{
    width: 80%;
    margin: 0 auto;
    border-radius: 3px;
    background: var(--gray-900, #141414);
    padding: 6rem;
    position: absolute;
    transition: transform 0.3s ease-in-out;
    border-radius: 2rem;
    margin-bottom: 2rem;
  }
  .name{
    margin-bottom:1rem;
    color: white;
    font-size: 3rem;
    font-weight: 700;
    padding-left:2rem;
  }
  .role{
    color: var(--gray-500, #757575);
    font-size: 18px;
  }
  .text{
   text-align: left;
  }
  .shape{
    width:15rem;
    height:15rem;
    float: left;
    background-color: black;
    clip-path: circle(50% at 50% 50%);
    transform: translateX(-4rem);
  }
  .photo{
    height: 100%;
  }
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 8px;
    background: var(--gray-600, #545454);
    background: var(--gray-600, #272525);
    border: none;
    padding: 7px 7px;
    cursor: pointer;
  }
  
  .carousel-button.prev {
    font-size: 3rem;
    left: 4rem;
    color:white;
    top:15rem;
  }
  
  .carousel-button.next {
    font-size: 3rem;
    right: 4rem;
    color:white;
    top:15rem;
  }
  @media (max-width:850px) {
    .heading{
      margin-bottom:3rem;
    }
    .link_photo{
      width: 8%;
    }
   
    .team{
      padding: 4rem;
    }
    .carousel-button.prev {
      font-size: 3rem;
      left: 1.5rem;
    }
    
    .carousel-button.next {
      font-size: 3rem;
      right: 1rem;
    }
  }
  @media (max-width:500px) {
    .team{
      padding: 1.7rem;
    }
    .shape{
      width: 10rem;
      height: 10rem;
      transform: translateX(-1rem);
    }
    .desc{
      font-size: 1.5rem;
    }
    .carousel-button.prev {
      font-size: 2rem;
   left:1rem;
    }
    
    .carousel-button.next {
      font-size: 2rem;
      right:0rem;
    }
  }
  @media (max-width:380px) {
    .link_photo{
      width: 10%;
    }
    .team{
      padding: 1.5rem;
    }
    .heading{
      padding-top:2rem;
      font-size: 4rem;
    }
    .name{
      font-size: 1.5rem;
    }
    .desc{
      font-size: 1.25rem;
    }
    .role{
      font-size:1.5rem;
    }

    .carousel-button.prev {
      font-size: 2rem;
   left:.5rem;
    }
    
    .carousel-button.next {
      font-size: 2rem;
      right:.5rem;
    }
    
  }
  .carousel-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px; 
  }
  
  .dot {
    width: 10px;
    height: 10px;
    margin: 0 5px; 
    background-color:rgb(70, 69, 69); 
    border-radius: 50%;
    transition: background-color 0.3s ease; 
  }
  
  .dot.active {
    background-color: white; 
    animation: pulse 1s infinite; 
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(2.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  </style>