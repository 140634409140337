<template>
  <section id="about" class="about section" >
    <div class="cooo">

      <div class="rw">
        <div class="column">
          <img src="../assets/hero_about.png" class="img-fluid" alt="">
        </div>
        <div class="colum-2">
          <h2> Thriber understands <br>your business.</h2>
          <h3>It's needs</h3>
          <p class="hero_para">We chart the course for a smooth sailing
            of your<br> business and your customers in<br> the stormy digital seas of today’s time.</p>
        <div class="read-more"> <a href="#contact" class="btnn">Contact us</a></div>
        </div>
      </div>

    </div>
  </section>

  </template>
  
  <script>
  export default {
    name: 'AboutSection'
  }
  </script>
  
  <style scoped>
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  
  html {
    font-size: 62.6%; 
  }
  .cooo{
    background-color: #000;
  }
  body {
      font-family: "latos",sans-serif;
      font-weight: 400;
      font-size: 1.6rem;
      color: #777;
      padding: 0rem;
      box-sizing: border-box; 
      overflow: hidden;   
}
.rw{
  display: flex;
  margin: 0 auto;

}
.column{
     padding: 5rem 5rem 5rem;
    
}
.colum-2{
    padding: 5rem 0 5rem;
}
.img-fluid{
   max-width: 100%;
   border-radius: 3rem;
   background: linear-gradient(143deg, #12D4E2 0%, #E2060A 100%),
}
.colum-2 h2{
  font-size:60px;
  color: white;
  font-weight: 700;
}
.colum-2 h3{
  padding-top: 3rem;
    font-size:60px;
    color: white;
    font-weight: 700;
}
.hero_para{
        padding: 5rem 0 5rem;
        font-size: 30px;
        color: var(--gray-500, #757575);
        
}
.read-more{
  position: relative;
  margin-bottom:10rem;
}
.btnn{
  max-width: 50%;
  padding: 2rem 4rem;
  font-size: 16px;
  border-radius: 16px;
  background: linear-gradient(90deg, #12DBE5 0%, #1F5799 48.96%, #F20000 100%);
  color: white;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%,-50%);
  text-decoration: none;

}



@media (max-width:1100px){
  .colum-2 h2{
    font-size:35px;
  }
  .colum-2 h3{
    font-size:35px;
  }
   .hero_para{
    font-size: 25px;
    padding: 3rem 0 3rem;
   }
   .read-more{
    padding: 1rem 2rem;
   }
   .btnn{
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-50%,-50%);
   }
}

@media (max-width:800px){
  .column{
    padding: 0;
    align-items: center;
  
   
}
.colum-2{
  padding: 1rem;
}
.img-fluid{
  max-width:80%;
  position: relative;
  left: 10%;
}
  .rw{
    flex-direction: column;
  }
  .colum-2 h2{
    font-size:30px;
    text-align: center;
    padding-top:4rem;
  }
  .colum-2 h3{
    font-size:30px;
    text-align: center;
    padding-top: 1rem;
  }
   .hero_para{
    font-size: 20px;
    padding: 3rem 0 3rem;
    text-align: center;
   }
   .read-more{
    font-size: 1rem;
    padding: 2rem 2rem;
   }
   .btnn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
   }
}
 @media (max-width:300px) {
  .btnn{
    max-width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
   }
   .read-more{
    padding: 2rem;
   }
}
  </style>
  