<template>
    <div class="privacy">
       <p class="pp"> Thriber takes your privacy very seriously.</p>

<p class="pp">In general, you can visit Thriber’ website without telling us who you are and without revealing any information about you.There are times, however, when we may need information from you.</p>

<p class="pp">You may choose to give us personal information, such as your name and address or e-mail id that may be needed, for example, to correspond with you, to download our white papers or to provide you with a subscription. If you tell us that you do not want us to use this information as a basis for further contact with you, we will respect your wishes. We intend to protect the quality and integrity of your personally identifiable information.</p>

<p class="pp">We will make a sincere effort to respond in a timely manner to your requests to correct inaccuracies in your personal information. To correct inaccuracies in your personal information please return the message containing the inaccuracies to the sender with details of the correction requested.</p>

<h3  class="head_p">Cookies and other technologies</h3>
<p class="pp">We sometimes collect anonymous information from visits to our sites to help us provide better customer service. For example, we keep track of the domains from which people visit and we also measure visitor activity on Thriber web site, but we do so in ways that keep the information anonymous. Thriber or its affiliates or vendors may use this data to analyze trends and statistics and to help us provide better customer service. We maintain the highest levels of confidentiality for this information, our affiliates and vendors follow the same high levels of confidentiality. This anonymous information is used and analyzed only at an aggregate level to help us understand trends and patterns. None of this information is reviewed at an individual level. If you do not want your transaction details used in this manner, you can either disable your cookies or opt-out at the download or request page.</p>

<h3 class="head_p">Anti spam policy</h3>
<p class="pp">Thriber recognizes the receipt, transmission or distribution of spam emails (unsolicited bulk emails) as a major concern and has taken reasonable measures, to minimize the transmission and effect of spam emails in its computing environment. All emails received by Thriber, are subject to spam check. Any email identified as spam will be rejected with sufficient information to the Sender for taking necessary action. With this measure, along with other technical spam reduction measures, Thriber hopes to minimize the effect of spam emails. Thriber reserves the right to reject and/or report any suspicious spam emails, to the authorities concerned, for necessary action, from time to time.</p>
    </div>
</template>
<script>

export default{
    name:'PrivacyPolicy',
}
</script>

<style>
*,
    *::after,
    *::before {
      margin: 0;
      padding: 0;
      box-sizing: inherit; 
      
    }
    body{
        background-color: black;
    }
.privacy{
    font-size: 21px;
    background: black;
    text-align: left;
    padding-left: 2rem;
    padding-right: 2rem;
    color: white;
    margin: 2rem;
    
}
.head_p{
    font-size: 4rem;
    font-weight: 600;
    margin-top: 4rem;
    margin-bottom: 1rem;
}
.pp{
    margin-bottom: 2rem;
    text-align:left;
    line-height: 3.2rem;
    display: inline-block;
    align-items: left;
    text-align:justify;
    text-justify:inter-word;
    width: auto;

}
</style>