<template>
    <nav class="navbar">
        <img alt="" src="../assets/Thriber_Logo3D_Trabsparent_4.png" class="logo">
      
      <ul :class="['nav-links', { 'active': isNavOpen }]">
        
      <button class="close-button" v-if="isNavOpen" @click="toggleNav">X</button>
        <li><a href="/">Home</a></li>
        <li><a href="/#thribermode">Thriber Mode</a></li>
        <li><a href="/#about">About</a></li>
        <!-- <li><a href="#ourteam">Our Team</a></li> -->
        <li><a href="/#contact">Contact Us</a></li>
        <li><a href="/health">Services</a></li>
      </ul>
      <form class="d-flex">
          <button class="btn btn-primary"><a href ="#contact">
              Contact us</a>
          </button>
      </form>
    
      <img alt="" src="../assets/Menu.png" class="mobile-nav-toggle" @click="toggleNav">
      <!-- <div class="overlay" v-show="isNavOpen" @click="toggleNav">
        <ul class="overlay-links">
          <li><a href="#">Home</a></li>
          <li><a href="#thribermode">Thribermode</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
        
      </div> -->
      
    </nav>
  </template>
  
  <script>
  export default {
    name: 'NavbarSection',
    data() {
      return {
        isNavOpen: false,
      };
    },
    computed: {
      screenWidth() {
        return window.innerWidth;
      },
    },
    methods: {
      toggleNav() {
        this.isNavOpen = !this.isNavOpen;
      },
    },
  };
  </script>
  
  <style>
  @import url('https://fonts.cdnfonts.com/css/inter');
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    position: sticky;
    z-index: 999;
    top: 0;
    left: 0;
  }
  
  .logo {
    width:5% ;
  }
 
  .mobile-nav-toggle {
    color: black;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
    }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
  }

  ul{
    text-align: center;
    margin-bottom: unset;
  }
  li{
    font-size: 20px ;
  }
  
  .nav-links li a {
    text-decoration: none;
    color: #fff;
    transition: color 0.3s ease;
  }
  
  .nav-links li a:hover {
    color: #1F5799;
  }
  .close-button {
    background-color: #444;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;

}

.overlay.active .close-button {
    display: block;
}
  /* Media Query for Responsive Navbar */
  @media (max-width: 768px) {
    .logo{
        width: 16%;
    }
    .mobile-nav-toggle {
    display: block;
    }
  
    .nav-links {
      display: none;
      flex-direction: column;
      background-color: black;
      position: absolute;
      top: 10px;
      right: 0;
      height: auto;
      width: 80%;
      z-index: 1;
      padding: 49px 19px 42px 0px;  
    }
  
    .nav-links.active {
      display: block;
      border-radius: 4rem;
      border-style: solid;
      border-color: white;
    }
  
    .nav-links li {
      padding: 10px;
      text-align: center;
      border-bottom: .5px solid grey;
    }

    .close-button{
      display: block;
      border-radius: 2rem;
      right: 10;
    }

  }
  @media (max-width: 600px) {
    .nav-links.active{
      height: auto;
    }
  }
  .btn-primary{
    border-radius: 10px;
    background: linear-gradient(90deg, #12DBE5 0%, #1F5799 48.96%, #F20000 100%);
    transition: .3s ease;
    padding: 10px 20px;
    color: #fff;
    border: 1px solid #000;
    }
    .btn-primary a{
      color: #fff;
      font-size: 16px;
      text-decoration: none;
    }

  </style>
  
