import { createApp } from 'vue';
/*imports for the router*/
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import Privacy from './components/PrivacyPolicy.vue';
import health from './components/health.vue';
/*file in which routers are created*/
import ThriberMode from './components/Thribermode.vue';
import Pageviewer from './components/Pageviewer.vue';
/* fuction for creating route*/
const router = createRouter({ 
    history:createWebHistory(),
    routes:[{
        name:'ThriberMode',
        path:'/Thribermode',
        component:ThriberMode,
        meta: {
            sitemap: 'http://www.thriber.com/digital_Branding.xml', 
          },
    },
    {
        name:'ThriberMode',
        path:'/Thribermode',
        component:ThriberMode,
        meta: {
            sitemap: 'http://www.thirber.com/engagement.xml', 
          },
    },
    {
        name:'ThriberMode',
        path:'/Thribermode',
        component:ThriberMode,
        meta: {
            sitemap: 'http://www.thriber.com/digitalmarketing.xml', 
          },
        },
        {
           name:'Privacy',
           path:'/PrivacyPolicy',
           component:Privacy,
        },
        {
            name:'Pageviewer',
            path:'/',
            component:Pageviewer,
         },
         {
            name:'healthServices',
            path:'/health',
            component:health,
         }
         

    ]
});


const app = createApp(App)  
app.use(router);
app.mount('#app');
