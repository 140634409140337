<template>
  <div class="dim-overlay" v-if="showPopup" >
  <div class="popup-form-container" >
    <button type="button" id="closePopupButton" class="closebutton" @click="closePopup">x</button>
    <h2>Get in Touch!</h2>
    <form id="popupForm" action="https://script.google.com/macros/s/AKfycbxpzVSGAM2uKTWynt23zXyyKtwORovZ2rByhaT0ErGOZBBd-YrkSNm5vlwopAaV-rs2/exec" method="POST" class="formcontain" @submit.prevent="submitForm">
      <input type="text" name="Name" placeholder="Full Name" required>
      <input type="text" name="Contact" placeholder="Mobile Number" required>
      <button type="submit" class="sendbtn">Send</button>
    </form>
  </div>
</div>
</template>

<script>
export default {
  name: 'PopupForm',
  data() {
    return {
      showPopup: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showPopup = true;
    }, 5000);
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    submitForm() {
      console.log("submitForm() called!");
        const form = document.getElementById('popupForm');
            const data = new FormData(form);
            const action = form.action;

            fetch(action, {
                method: 'POST',
                body: data,
            })
                .then(() => {
                this.showAlert = true;
                alert('Thanks! We Will Contact You Soon.');
                form.reset();
                })
                .catch((error) => console.log(`Error submitting contact form ${error}`));
                this.showPopup = false;
            },
  },
};
</script>

<style scoped>

.dim-overlay{
  position: fixed;
  background-color: #1f1f1f80;
  padding: 100%;
  z-index: 9999;
}
.popup-form-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 250px;
  z-index: 100;
  font-family: sans-serif;
  text-align: center;
  background-color: #1f1f1f;
  border-radius: 25px;
}

.popup-form-container h2{
  color: #fff;
  font-size: 2.5rem;
}

.formcontain {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 12px;
}

input{
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
  
}

input::placeholder{
  font-family: sans-serif;
}

.closebutton{
    cursor: pointer;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
    font-size: 20px;
    border: 0px;
    left: 142px;
    top: 5px;
    position: relative;
    background-color: #1f1f1f;
}

.sendbtn{
  background: linear-gradient(90deg, #12DBE5 0%, #1F5799 48.96%, #F20000 100%);
  border: 0px;
  padding: 5px;
  border-radius: 10px;
  color: #fff;
}

</style>
