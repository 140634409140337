<template>

    <section id ="thribermode">
        
    <div class="mode-setion">
        <router-link class="router_link" to="/ThriberMode"><h1 class="ques">What is Thriber Mode?</h1></router-link>
        <p class="quick">As the world moves from traditional form of print media and television to<br> digital platforms for reach,your business needs the thriber mode enabled.</p>

    
        <div class="rw">
    
        <div class="digital_branding" id="mode">
            <img src="../assets/digital_branding.png" alt="Logo" class="img_fluid">
            <div class="description">
                <!--- this is a route link which will work as an anchor tag-->
            <router-link class="router_link" to="/Digital_branding"><h1 class="head">Digital branding</h1></router-link>
            <p>Your business needs a  handsome website,a beautiful logo, a succinct brochure ? You name it. We got you covered.</p>

        </div>
        </div>
    
        <div class="customer_reach">
            <img src="../assets/Networking_perspective_matte_s.png" alt="Logo" class="img_fluid">
            <div class="description">
                <!---router link-->
            <router-link class="router_link" to="/Customer_reach"><h1 class="head">Customer reach</h1></router-link>
            <p>We will look after your social media reach and get you the audience your business deserves.</p>

            </div>
        </div>
        <div class="engagement">
             <img src="../assets/engage.png" alt="logo"  class="img_fluid">
             <div class="description">
                 <!---router link-->
             <router-link class="router_link" to="/Engagement"><h1 class="head">Engagement</h1></router-link>
             <p>Create engaging content around your business.Educate<br> your customers on what your business can do for them.</p>
             </div>
        </div>
    
        <div class="analysis">
            <img src="../assets/Chart_perspective_matte_s.png" alt="Logo" class="img_fluid">
            <div class="description">
                 <!---router link-->
            <router-link class="router_link" to="/Analysis"><h1 class="head">Analysis</h1></router-link>
            <p>Analyse your business performance through centralised data aggregation and metrics.Get intelligent insights to make better decisions for your business.</p>
        </div>
        </div>
    </div>
    </div>
    </section>
    </template>
    
    <script>
    export default{
        name: 'ThriberMode'
    }
    
    </script>
    
    <style>
    *,
    *::after,
    *::before {
      margin: 0;
      padding: 0;
      box-sizing: inherit; 
    }
    
    html {
      font-size: 62.5%; 
    }
    
    body {
        font-weight: 400;
        font-size: 1.6rem;
        color: #777;
        padding: 0rem;
        box-sizing: border-box; 
    }
    
    .mode-setion{
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color:black;
        color:white;
        margin: 0;
        padding: 0;
        overflow: hidden;
        padding: 40px 0;
       
    }
    
    .rw h1{
        font-size: 50px;
        text-align: center;
        margin: 0;
    }
    
    .ques{
        color: var(--primary-white-100, #FFF);
        text-align: center;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        padding: 10px;
        padding-bottom: 20px;
       
    
    }
    .router_link{
        text-decoration: none;
        color:white;
    }
    a:hover{
        color:rgba(0, 170, 255, 0.904);
        text-decoration: none;
    }
    .rw p{
        margin: 0;
        color: var(--gray-500, #757575);
        font-weight: 600;
    }
    
    .quick{
        font-size: 35px;
        color: var(--gray-500, #757575);
        padding-bottom: 2rem;   
        font-weight: 600;
    }
    
    .mode-setion .rw{
        display:grid;
        grid-template-rows: 2fr 2fr;
        grid-template-columns: 1fr 1fr ;
        padding: 2rem;
        width: 100%;
    }
    .description{
        max-width: 95%;
        padding: 2rem;
    }
    
    .digital_branding{
        max-width: 95%;
    color: var(--primary-white-100, #FFF);
    text-align: center;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    }
    
    .customer_reach{
        max-width: 95%;
        color: var(--primary-white-100, #FFF);
        text-align: center;
        
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
    }
    
    .engagement{
        position: relative;
        max-width: 95%;
        color: var(--primary-white-100, #FFF);
        text-align: center;
        
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
    }
    
    .analysis{
        max-width: 95%;
        color: var(--primary-white-100, #FFF);
        text-align: center;
        
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
    }
    .img_fluid{
        max-width: 95%  ;
    }
    .head{
        padding: 10px;
        text-decoration: none;
    }
    @media (max-width:850px) {
        .rw p{
            font-size: 2rem;
            font-weight: 500;
            line-height: 1;
            text-align: left;
        }
       .rw h1{
            font-size: 3rem;
            font-weight: 500;
            padding: 10px;
        }
        .ques{
            font-size: 5rem;
            font-weight: 600;
        }
        .quick{
            font-size: 3rem;
            font-weight: 600;
        }
        
    }
    @media (max-width:600px) {
        .mode-setion .rw{
            grid-template-rows: 1fr ;
            grid-template-columns: 2fr  ;
    
        }
        .ques{
            font-size: 4rem;
        }
        .quick{
            font-size: 2rem;
        }
        
    }
    
    </style>