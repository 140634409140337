<template>
  <div id="app">
    <div>
      <Popup/>
    </div>
    <Navbar/>
    <router-view></router-view>
    <Footer/> 
    
  </div>
 


</template>

<script>

import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import Popup from './components/popup.vue';
export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    Popup
},
  data() {
    return {
      currentSection: 'about'
    };
  },
  methods: {
    showSection(section) {
      this.currentSection = section;
    }
  }
  
}

</script>

<style>
/* Add your global styles here */
.nav {
  display: flex;
  justify-content: center;
  background-color: #444;
  padding: 10px;
}

.nav button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  margin: 0 10px;
}

.nav button:hover {
  text-decoration: underline;
}
</style>