<template>
<div class="container">
    <h1>The technology partner that <br>your business needs to thrive <br>in the digital economy.</h1>
    <h2>We help real businesses grow by enabling <br>the right technologies for them.</h2>
    <div class="img-container">
    <img alt="" src="../assets/Dark.png" class="dark">
    <img alt="" src="../assets/Dark.png" class="dark">
    <img alt="" src="../assets/connecthand.png" class="light">
    <img alt="" src="../assets/Dark.png" class="dark">
    <img alt="" src="../assets/Dark.png" class="dark">
</div>
</div>
<Thribermode/>
<about/>
<our_team/>
<Contact/>
</template>
 
<script>
import Thribermode from './Thribermode.vue';
import About from './about.vue';
import Contact from './contact.vue';
import our_team from './our_team.vue';
export default{
    name:'PageViewer',
    components:{Thribermode,About,Contact,our_team},


}


</script>

<style>
@import url('https://fonts.cdnfonts.com/css/inter');
    .container{
    background-color: #000;
    max-width: 100%;
    padding: 20px 20px;
    }
    
    
    .container h1{
        color: #FFF;
        text-align: center;
        font-size: 70px;
        font-style: normal;
        font-weight: 700;
        line-height: 82px; /* 116.667% */
        letter-spacing: -3.84px;
        padding-bottom: 20px;
    }

    .container h2{
        color: var(--gray-500, #757575);
        text-align: center;
        font-size: 35px;
        font-style: normal;
        padding-bottom: 20px;
        font-weight: 600;
        line-height: 40px; /* 114.286% */
        letter-spacing: -0.7px;
    }
    .img-container {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    }

    .image-item {
    height: auto;
    margin: 10px;
    border: 1px solid #ccc;
    }

    .dark {
    width: 17%;
    border-radius: 50px;
    }

    .light {
    width: 25%;
    border-radius: 50px;
    }

    @media (max-width: 768px) {
        .container h1{
            padding: 0px;
            font-size: 50px;
            line-height: 65px;
            letter-spacing: -3.1px;
        }

        .container h2{
            padding: 0px;
            font-size: 30px;
        }
        .dark {
        width: 17%;
        border-radius: 15px;
        }

        .light {
        width: 25%;
        border-radius: 25px;
        }
        
    }
    @media (max-width: 1200px) {
        .container h1{
            padding: 0px;
            font-size: 50px;
            line-height: 65px;
            letter-spacing: -3.1px;
        }

        .container h2{
            padding: 0px;
            font-size: 30px;
        }

        br{
            display:none;
        }
        .dark {
        width: 17%;
        border-radius: 15px;
        }

        .light {
        width: 25%;
        border-radius: 25px;
        }
    }

</style>