<template>
    <section id="contact" class="contact-section">
      <div class="contact">
        <div class="detail">
      <h2>Enable Thriber mode for your business to thrive!</h2>
      <p>Reach out to us for an engaging discussion on how we can help your business grow without bounds and realise its true potential.</p>
      <div class="image">
        <a :href="'tel:' + phoneNumber"><img src="../assets/Phone.png" alt="" class="phone"></a>
      <a href="https://wa.me/918104531950"><img src="../assets/Whatsapp.png" alt="" class="whatsapp"></a>
      <a :href="'mailto:' + emailAddress"><img src="../assets/Mail.png" alt="" class="mail"></a>
    </div>
    </div>
    <div class="Form">

      <form class="container"  action="https://sheetdb.io/api/v1/j7xxw4zakmyeh" method="post" id="sheetdb-form">
        <input type="text" class="name" onkeydown="return /[a-z ]/i.test(event.key)" name="data[name]" placeholder="Name" required>
        <input type="text" class="number" onkeydown="return this.value.length <= 9 && /[0-9\b]/.test(event.key) || event.keyCode === 8" name="data[contact]" placeholder="Phone No." required>
        <textarea class="message" rows="5" name="data[message]" placeholder="Message" required></textarea>
        <div class="sent-message" id="success-message">Your message has been sent. Thank you!</div>

        <button type="submit" class="submit">Send</button>
 

      </form>
    </div>
    </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'ContactSection',

    data() {
    return {
      phoneNumber: '8104531950',
      emailAddress: 'accelerate@thriber.com',
    };
  },

    mounted() {
      const form = document.getElementById('sheetdb-form');
      form.addEventListener("submit", e => {
        e.preventDefault();
        fetch(form.action, {
            method: "POST",
            body: new FormData(form),
        }).then(
            response => response.json()
        ).then((data) => {
          this.handleResponse(data);
        });
      });
    },
    methods: {
      handleResponse(data) {
        if (!data.success) {
          alert('Success');
        } 
      },
 
    },
  }
  </script>
  
  
  <style>
  .sent-message{
    display: none;
     border-radius: 2rem;
     color: #fff; 
     background: #24ef32; 
     text-align: center; 
     padding: 1rem; 
     font-weight: 600;
  }
  .contact {
    padding: 20px 150px;
    background-color: #000;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  .detail {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .detail h2{
    color: var(--primary-white-100, #FFF);
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px; /* 116.364% */
    letter-spacing: -2.75px;
    text-align: start;
    padding: 20px;
  }
  .detail p{
    color: var(--gray-500, #757575);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 133.333% */
  letter-spacing: -0.6px;
  text-align: start;
  padding: 20px;
  }
  .image{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 2rem;
  }
   .phone,.mail,.whatsapp{
    background-color: #444;
    border-radius: 5px;
  }

  .Form {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 25px;
      height: 100%;
      justify-content: center;
      position: relative;
      width: 100%;
  }
  .Form form {
    color: #FFF;
      margin-left: auto;
      width: 400px;
      padding: 20px;
      box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.06);
      border-radius: 32px;
      background: var(--gray-800, #1F1F1F);
  }

  .container .name, .number {
      width: 100%;
    color: #FFF;
      margin-bottom: 10px;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #000;
      border-radius: 15px;
      background-color: #000;
  }
  .container .message {
    width: 100%;
    color: #FFF;
      margin-bottom: 10px;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #000;
      border-radius: 15px;
      background-color: #000;

  }
  .submit{
    color:#FFF;
    border-radius: 16px;
    border: 1px solid #000;
    background: linear-gradient(90deg, #12DBE5 0%, #1F5799 48.96%, #F20000 100%);
    padding: 10px 30px;
    font-size: 16px;
    align-items: start;
  }
  
  @media (max-width: 1200px) {
    .contact{
      padding: 5px;
      text-align: center;
    }
    .Form{
      padding: 0px;
      height: 0px;
    }
       .Form h2{
            padding: 0px;
            font-size: 50px;
            line-height: 65px;
            letter-spacing: -3.1px;
        }

       .Form p{
            padding: 0px;
            font-size: 30px;
        }
        
    }

    @media (max-width: 768px) {
    .contact{
      padding: 5px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      height: 1300px;
    }
    .Form{
      padding: 0px;
      height: 0px;
    }
       .Form h2{
            padding: 0px;
            font-size: 50px;
            line-height: 65px;
            letter-spacing: -3.1px;
        }

       .Form p{
            padding: 0px;
            font-size: 30px;
        }
        
    }
  @media (max-width: 600px) {
    .contact{
      padding: 5px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      height: 1300px;
    }
    .Form{
      padding: 0px;
      height: 0px;
    }
       .Form h2{
            padding: 0px;
            font-size: 50px;
            line-height: 65px;
            letter-spacing: -3.1px;
        }

       .Form p{
            padding: 0px;
            font-size: 30px;
        }
        
    }
    @media(max-width:600px){
      .contact{
        height: 150rem;
      }
    }
  </style>
  