<template>
    <footer class="footer">
        <hr>

        <div class="lower">
        <p>&copy;2023, Thriberx Engineering Pvt Ltd. All rights reserved.<RouterLink to="/PrivacyPolicy" class="privacypolicy"> Privacy Policy </RouterLink></p>
      <div class="image1">
        <a href="https://www.facebook.com/profile.php?id=100094732093918">
        <img src="../assets/Facebook.png" alt="" class=""></a>
        <a href="https://instagram.com/thribertech?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"><img src="../assets/Instagram.png" alt="" class=""></a>
        <a href="https://www.linkedin.com/company/thriberx/"><img src="../assets/LinkedIn.png" alt="" class=""></a>
      </div>

      
      </div>
      
    </footer>
  </template>
  
  <script>
  import { RouterLink } from 'vue-router';
  export default {
    name: 'FooterSection',
    components:{RouterLink}
  }
  </script>
  
  <style>
  .footer {
    text-align: center;
    padding: 2%;
    background-color: #000;
    color: white;
  }
  .upper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .logoimg{
    max-width: 5%;
  }
  .btn-primary{
    padding: 10px 20px;
  }

  .lower{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .image1{
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .privacypolicy{
    text-decoration: none;
    color: #fff;
  }
  </style>
  